














import { Component, Prop, Watch } from 'vue-property-decorator';
import { UserStoreModule } from '@common-src/store/modules/user';
import { BaseComponent } from '@common-src/mixins/base-component';

@Component
export default class AppPage3Component extends BaseComponent {
    @Prop()
    routePath: string;
    @Prop()
    padding: string;
    height: number = 0;

    created() {
        window.addEventListener('resize', this.onResize);
        this.onResize();

        window.addEventListener('message', (event) => {
            // 边缘端 嵌入页面内部跳转时对应菜单高亮
            if (['/monitor-alarm-list-edge', '/group-control-log'].includes(event.data)) {
                console.log('event.data', event.data);
                this.$router.push(`/dashboard${event.data}`);
            }
        });
    }

    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    }

    onResize() {
        let padding = 0;
        if (this.padding) {
            padding = parseInt(this.padding);
        }
        this.height = document.body.clientHeight - 55 - padding * 2;
    }

    @Watch('routePath')
    update(newVal) {
        const frame = document.getElementById('iframe-app3') as any;
        if (((frame.contentWindow) as any).closeAllModal) {
            ((frame.contentWindow) as any).closeAllModal();
        }
    }

    get RoutePath() {
        return `${window.location.origin}/app3/?token=${this.Token}&projectId=${this.ProjectId}&edgeKey=${this.EdgeKey}&edgeUserId=${this.UserId}&edgeOrigin=${window.location.origin}#${this.routePath}`;
        // return `${process.env.VUE_APP_3_DOMAIN}/?token=${this.Token}&projectId=${this.ProjectId}&edgeKey=${this.EdgeKey}#${this.routePath}`;
        // return `http://localhost:3001/?token=${this.Token}&projectId=${this.ProjectId}&edgeKey=${this.EdgeKey}&edgeUserId=${this.UserId}&edgeOrigin=${window.location.origin}#${this.routePath}`;
    }
    get Token() {
        // return window.localStorage.getItem('jtl_token');
        return UserStoreModule.Token;
    }

    get UserId() {
        if (this.isEdge) {
            return UserStoreModule.UserId || '';
        }
        return '';
    }

    get ProjectId() {
        return UserStoreModule.CurrentProjectId || '';
    }
    get StylePadding() {
        return this.padding || 'inherit';
    }

    get EdgeKey() {
        return localStorage.getItem('Edge-key') || '';
    }
}
