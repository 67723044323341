var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { style: { padding: _vm.StylePadding } }, [
    _c("iframe", {
      staticClass: "app-page3-iframe",
      attrs: {
        id: "iframe-app3",
        src: _vm.RoutePath,
        width: "100%",
        height: _vm.height + "px",
        frameborder: "no",
        border: "0",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }